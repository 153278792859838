import React, { useState } from 'react';
import styles from './Home.module.scss';
import PopularServices from '../../components/AboutComponents/PopularServices/PopularServices';
import AboutUs from '../../components/AboutComponents/AboutUs/AboutUs';
import Modal from '../../components/UI/Modal/Modal';
import QuotePopup from '../QuotePopup/QuotePopup';
import SocialMedia from '../../components/AboutComponents/SocialMedia/SocialMedia';
import Button from '../../components/UI/Button/Button';

const Home = (props) => {
    const [modal, setModal] = useState(false);

    return (
        <div className={styles.about}>
            <PopularServices examples={props.examples} />
            <AboutUs />
            <h3 className="header">Interested in a service?</h3>
            <Button onClick={() => setModal(true)}>Get a quote</Button>
            <h3 className="header">Find us on social media</h3>
            <SocialMedia />
            {modal ?
                <Modal close={() => setModal(false)}>
                    <QuotePopup subject="" />
                </Modal>
                : null}
        </div>
    )
}

export default Home;