import React, { useState } from 'react';
import styles from './ContactUs.module.scss';
import Button from '../../components/UI/Button/Button';
import Hours from '../../components/ContactUsComponents/Hours/Hours';
import Modal from '../../components/UI/Modal/Modal';
import QuotePopup from '../QuotePopup/QuotePopup';

const ContactUs = (props) => {
    const [modal, setModal] = useState(false);

    return (
        <div className={styles.contactus}>
            <Hours />

            <h3 className={styles["contactus__header"]}>Contact us:</h3>
            
            <a href="mailto:otto@cncworks.co.nz" className={styles["contactus__link"]}>
                <i className="icon-Mail" /> otto@cncworks.co.nz
            </a>
            <a href="tel:03 768 0346" className={styles["contactus__link"]}>
                <i className="icon-Phone" /> <span>Landline:</span> 03 768 0346
            </a>
            <a href="tel:03 768 0346" className={styles["contactus__link"]}>
            <i className="icon-Phone" /> <span>Ian Otto:</span> 027 438 2802
            </a>
            <a href="tel:03 768 0346" className={styles["contactus__link"]}>
                <i className="icon-Phone" /> <span>Thérèse Otto:</span> 029 127 3994
            </a>
            <Button colour="blue" onClick={() => setModal(true)}>Send a message</Button>

            <h3 className={styles["contactus__header"]}>Visit us:</h3>

            <iframe title="google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d735.5170182742169!2d171.177451329269!3d-42.49010540977607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d2f6b041958df1b%3A0xf6bd9d3c374ec664!2sOTTO%20ELECTRONIC%20%26%20CNC%20WORKS%20LTD!5e0!3m2!1sen!2snz!4v1622870693690!5m2!1sen!2snz" style={{ width: "50rem", height: "40rem", border: 0 }} allowFullScreen="" loading="lazy"></iframe>

            {modal ?
                <Modal close={() => setModal(false)}>
                    <QuotePopup subject="message" />
                </Modal>
                : null}
        </div>
    )
}

export default ContactUs;