import React from 'react';
import styles from './SecondNav.module.scss';
import SecondNavItem from './SecondNavItem/SecondNavItem';

const SecondNav = (props) => {
    let links = Object.keys(props.subs).map((link) => {
        return (
            <SecondNavItem notNav={props.notNav} click={props.click} key={link} route={props.route} name={link} link={props.subs[link]} />
        )
    });

    return (
        <div className={props.notNav ? styles['secondnav--services'] : styles.secondnav} >
            {links}
        </div>
    )
}

export default SecondNav;