import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Service.module.scss';
import { images } from '../../../../config';

const Service = (props) => {
    return (
        <Link to={props.category + "/" + props.example.replaceAll(",", "").replaceAll(" ", "")} className={styles.service}>
            <div><img src={images("Navigation", props.example.replaceAll(",", "").replaceAll(" ", ""))} alt="popular item" className={styles["service__image"]} /></div>
            {props.category === "cnccutting" ? props.example + " cutting" : props.example}
        </Link>
    )
}

export default Service;