import React from 'react';
import styles from './Button.module.scss';

const Button = (props) => {
    let classes = styles["button__inside"];

    if (props.colour === "blue") {
        classes = [styles["button__inside"], styles["button__inside--blue"]].join(" ");
    }
    else if (props.colour === "purple") {
        classes = [styles["button__inside"], styles["button__inside--purple"]].join(" ");
    }

    return (
        <button type="submit" onClick={props.onClick} className={styles.button}>
            <div className={classes}>
                {props.children}
            </div>
            <div className={styles['button__outside']} />
        </button>
    )
}

export default Button;