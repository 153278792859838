import React from 'react';
import styles from './Pictures.module.scss';
import { images } from '../../../config';

const Pictures = (props) => {
    return (
        <div className={styles.pictures}>
            <img src={images("Banners", props.route)} alt="Banner" />
        </div>
    )
}

export default Pictures;