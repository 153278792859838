import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter, useLocation, Switch } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import Footer from '../../components/Footer/Footer';
import PageNotFound from '../../components/PageNotFound/PageNotFound';
import Home from '../Home/Home';
import Gears from '../../components/Gears/Gears';
import Services from '../Services/Services';
import ContactUs from '../ContactUs/ContactUs';
import styles from './Layout.module.scss';
import Terms from '../../components/Terms/Terms';

const Layout = (props) => {
    const [toggle, setToggle] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 1);
    }, [pathname]);

    return (
        <React.Fragment>
            <Navigation examples={props.examples} toggle={toggle} setToggle={(bool) => setToggle(bool)} />

            <div className={styles.layout}>
                <Switch>
                    <Route path="/" exact>
                        <Home examples={props.examples.popular} />
                    </Route>
                    <Route path="/terms&conditions" exact>
                        <Terms />
                    </Route>
                    <Route path="/cnccutting/:sub" exact>
                        <Services route="cnccutting" examples={props.examples.cnc} />
                    </Route>
                    <Route path="/electronicrepairs/:sub" exact>
                        <Services route="electronicrepairs" examples={props.examples.electronics} />
                    </Route>
                    <Route path="/cnccutting" exact>
                        <Services route="cnccutting" examples={props.examples.cnc} />
                    </Route>
                    <Route path="/electronicrepairs" exact>
                        <Services route="electronicrepairs" examples={props.examples.electronics} />
                    </Route>
                    <Route path="/contactus" exact>
                        <ContactUs />
                    </Route>
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </div>

            <Gears left={true}/>
            <Gears left={false}/>
            <Footer />
        </React.Fragment>
    )
}

export default withRouter(Layout);