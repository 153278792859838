import React from 'react';
import styles from './Examples.module.scss';
import Example from './Example/Example';

const Examples = (props) => {
    let examples = props.examples.map((example) => {
        return (
            <Example route={props.route} key={example} example={example} />
        )
    });

    return (
        <div className={styles.examples}>
            {examples}
        </div>
    )
}

export default Examples;