import styles from './App.module.scss';
import Layout from './Layout/Layout';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  let examples = {
    cnc: {
      router: {
        description: [
          "Our Router flatbed table size is 6000mm x 2400mm, with a \
            depth of 100mm. ",
          "Material types: Aluminium, ACP (Aluminium Composite Panel/Material), \
            Acrylics, Perspex, Plywood, Polycarbonate sheets, \
            Polypropylene, MDF (Medium Density Fibre Board), Mulflute or Corflute, Wood (various types), Foam board. ",
          "Material thickness: 0.1mm to 100mm.",
          "Click \"Get a Quote\" and send us your design with the required dimensions, material specification and intended use. "
        ],
        examples: [
          "ACP Artwork - Motorbike",
          "ACP Cut artwork - Fern",
          "Mixed materials - sign",
          "Wood engraving",
          "Wood engraving fine",
          "Wood engraving Timber",
          "Wood room divider"
        ]
      },
      plasma: {
        description: [
          "Our Plasma flatbed table size is 3000mm x 2000mm.",
          "Material types: Aluminium, Stainless Steel, Steel (Various grades).",
          "Material thickness: \
            Aluminium, Stainless Steel & Steel: 0.2mm to 25mm.",
          "Click \"Get a Quote\" and send us your design with the required dimensions, material specification and intended use."
        ],
        examples: [
          "Steel Artwork - Beehive",
          "Steel Artwork - Deer",
          "Steel Artwork - Rose",
          "Steel Artwork - Sign",
          "Steel Base Plates",
          "Stainless Steel bracket",
          "Steel Mechanical component",
          "Steel spacer"
        ]
      }
    },
    electronics: {
      "mobile devices": {
        description: [
          "We repair all makes and models of mobile devices.",
          "Examples of common repairs we do:",
          "Battery replacements, LCD/Screen replacements, button replacements, charging port replacements, camera replacements, \
          speaker replacements, liquid damage repairs, and board repairs.",
          "We have state of the art equipment and are highly specialised in all PC board level repairs.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      },
      "computers, laptops": {
        description: [
          "We repair all makes and models of computers, laptops, notebooks, tablets and all types of office equipment.",
          "Examples of common repairs we do:",
          "LCD/Screen replacements, battery replacements, mother/main board repairs, button/keyboard replacements, \
          camera replacements, liquid damage repairs, charging port repairs, hard drive repairs & replacements and speaker replacements.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      },
      "apple devices": {
        description: [
          "We specialise in all repairs of Apple iPhones, MacBooks, MacBook Pro's, MacBook Air's, iPads, iMax's and Notepads.",
          "Examples of common repairs we do:",
          "LCD/Screen replacements, battery replacements, motherboard repairs, button replacements, camera replacements, liquid damage \
          repairs, charging port repairs, hard drive replacements and repairs, speaker replacements.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      },
      "cctv systems": {
        description: [
          "We sell, service and repair all CCTV camera systems. We also upgrade and maintain CCTV camera systems, \
          whether it is for your home, office, farm or warehouse.",
          "We also offer battery backups and UPS (Uninterruptable Power Supply) systems.",
          "Examples of common repairs that we do:",
          "CCTV System upgrades, Camera repairs, DVR repairs, lightning damage repairs and water damage repairs.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      },
      "pc boards & other": {
        description: [
          "We repair ALL Electronic Boards and other systems, damaged by liquid, lightning or malfunctions.",
          "Examples of common repairs that we do:",
          "PC boards, Main boards, Logic boards, any Mother boards, Automotive boards, Telecommunication boards, \
          Amplifiers and Receiver system boards.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      },
      "telecom equipment": {
        description: [
          "We service and repair all makes and models of Telecommunication devices and equipment such as:",
          "Cambium, Duxbury, Motorola, Mikrotik, Netgear, Proxim, Ruckus, and TP-Link to name a few.",
          "We have over 10 years of high-level board repairs experience for all these units.",
          "We also repair radio transmitters and receivers, and communication equipment with any \
          lightning damage, power surges or spikes damage or water damage.",
          "Click \"Get a Quote\", and send us a message.",
          "We will be able to assist you with any type of repair you have."
        ]
      }
    },
    popular: {
      "pc boards & other": "electronicrepairs",
      "computers, laptops": "electronicrepairs",
      plasma: "cnccutting",
      "apple devices": "electronicrepairs",
      "mobile devices": "electronicrepairs"
    }
  }

  return (
    <div className={styles.app}>
      <Router>
        <Layout examples={examples} />
      </Router>
    </div>
  );
}

export default App;
