import React from 'react';
import styles from './SocialMedia.module.scss';
import facebook from '../../../assets/images/facebook.svg';
import instagram from '../../../assets/images/instagram.svg';
import twitter from '../../../assets/images/twitter.svg';
import pinterest from '../../../assets/images/pinterest.svg';

const SocialMedia = (props) => {
    return (
        <div className={styles.socialmedia}>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/OTTO-Electronic-and-CNC-Works-106616784991761">
                <img className={styles["socialmedia__image"]} src={facebook} alt="facebook" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/otto.cnc/">
                <img className={styles["socialmedia__image"]} src={instagram} alt="facebook" />
            </a>
            <a target="_blank" rel="noreferrer" href="http://www.twitter.com">
                <img className={styles["socialmedia__image"]} src={twitter} alt="facebook" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.pinterest.nz/oecwltd/_created/">
                <img className={styles["socialmedia__image"]} src={pinterest} alt="facebook" />
            </a>
        </div>
    )
}

export default SocialMedia;