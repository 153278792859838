import React, { useState } from 'react';
import NavigationMobile from '../../components/NavigationComponents/NavigationMobile/NavigationMobile';
import NavItem from '../../components/NavigationComponents/NavItem/NavItem';
import NavLogo from '../../components/NavigationComponents/NavLogo/NavLogo';
import styles from './Navigation.module.scss';
import NavigationDropdownButton from '../../components/NavigationComponents/NavigationMobile/NavigationDropdownButton/NavigationDropdownButton';

const Navigation = (props) => {
    const [navToggle, setNavToggle] = useState(false);

    let links = [{ name: "home", subs: null },
    { name: "cnc cutting", subs: props.examples.cnc },
    { name: "electronic repairs", subs: props.examples.electronics },
    { name: "contact us", subs: null }].map((link) => {
        let route = link.name;

        if (link.name === "home") {
            route = "";
        }

        return (
            <div key={link.name} className={styles['navigation__dropdown']}>
                <NavItem hide={true}
                    link={"/" + route.replaceAll(" ", "")}
                    text={link.name}
                    onClick={() => props.setToggle(false)}
                    name={link.name}
                    subs={link.subs} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <nav className={styles.navigation}>
                <NavigationDropdownButton navToggle={navToggle} setNavToggle={(bool) => setNavToggle(bool)} />
                <NavLogo />
                {links}
            </nav>

            {navToggle ? <NavigationMobile setNavToggle={() => setNavToggle(false)} setToggle={() => props.setToggle(false)} /> : null}
        </React.Fragment>
    )
}

export default Navigation;