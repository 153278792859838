import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SecondNavItem.module.scss';
import { images } from '../../../../config';

const SecondNavItem = (props) => {
    return (
        <Link onClick={props.click} to={"/" + props.route + "/" + props.name.replaceAll(" ", "").replaceAll(",", "")} className={props.notNav ? styles['secondnavitem--services'] : styles.secondnavitem}>
            <div><img src={images("Navigation", props.name.replaceAll(" ", "").replaceAll(",", ""))} alt="second nav item" className={props.notNav ? styles['secondnavitem--services__image'] : styles["secondnavitem__image"]} /></div>
            <p>{props.name}</p>
        </Link>
    )
}

export default SecondNavItem;