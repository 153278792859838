import React from 'react';
import styles from './PageNotFound.module.scss';

const PageNotFound = (props) => {
    return (
        <div className={styles.pagenotfound}>
            Page not found
        </div>
    )
}

export default PageNotFound;