import React from 'react';
import styles from './Modal.module.scss';
import Backdrop from '../Backdrop/Backdrop';

const Modal = (props) => {
    return (
        <React.Fragment>
            <Backdrop close={props.close} />
            <div className={styles.modal} >
                {React.cloneElement(props.children, { close: props.close })}
            </div>
        </React.Fragment>
    )
}

export default Modal;