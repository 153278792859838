import React from 'react';
import styles from './Hours.module.scss';

const Hours = (props) => {
    return (
        <div className={styles.hours}>
            <h3>Opening hours:</h3>

            <table className={styles["hours__table"]}>
                <tbody><tr>
                    <td>Monday</td>
                    <td>8am-5pm</td>
                </tr>
                    <tr>
                        <td>Tuesday</td>
                        <td>8am-5pm</td>
                    </tr>
                    <tr>
                        <td>Wednesday</td>
                        <td>8am-5pm</td>
                    </tr>
                    <tr>
                        <td>Thursday</td>
                        <td>8am-5pm</td>
                    </tr>
                    <tr>
                        <td>Friday</td>
                        <td>8am-4pm</td>
                    </tr>
                    <tr>
                        <td>Saturday</td>
                        <td>Closed</td>
                    </tr>
                    <tr>
                        <td>Sunday</td>
                        <td>Closed</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Hours;