import React from 'react';
import styles from './NavigationMobile.module.scss';
import NavItem from '../NavItem/NavItem';
import NavLogo from '../NavLogo/NavLogo';

const NavigationMobile = (props) => {
    const toggle = () => {
        props.setNavToggle();
        props.setToggle();
    }
    
    let count = 0.2;

    let links = ["home", "cnc cutting", "electronic repairs", "contact us"].map((name) => {
        count += 0.1;

        let route = name;

        if (name === "home") {
            route = "";
        }

        return(
            <NavItem mobile={true} onClick={toggle} delay={count} key={name} link={"/" + route.replaceAll(" ", "")} text={name} />
        );
    });


    return (
        <nav className={styles.navigationmobile}>
            <NavLogo mobile={true} onClick={toggle} delay={0.2} />
            {links}
        </nav>
    )
}

export default NavigationMobile;