import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styles from './Services.module.scss';
import Examples from '../../components/ServicesComponents/Examples/Examples';
import Pictures from '../../components/ServicesComponents/Pictures/Pictures';
import QuotePopup from '../QuotePopup/QuotePopup';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import SecondNav from '../../components/NavigationComponents/SecondNav/SecondNav';
import PageNotFound from '../../components/PageNotFound/PageNotFound';

const Services = (props) => {
    const [modal, setModal] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => {
        setModal(false);
    }, [pathname]);

    let { sub } = useParams();
    let header = "";

    
    if (sub) {
        let examples = [];
        
        let pageFound = false;
        
        for (let ex of Object.keys(props.examples)) {
            let i = ex.replaceAll(" ", "").replaceAll(",", "");
            
            if (i === sub) {
                pageFound = true;
                examples = props.examples[ex]
                header = ex;
            }
        }

        if (!pageFound) {
            console.log(sub)
            return <PageNotFound />
        }

        let blurbs = examples.description.map((blurb) => {
            return (
                <p className="text" key={blurb}>{blurb}</p>
            )
        });

        return (
            <div className={styles.services}>
                <Pictures route={sub} />

                <h3 className="header">{header}</h3>

                <div className={styles["services__blurbs"]}>
                    {blurbs}
                </div>

                <Button onClick={() => setModal(true)}>Get a quote</Button>

                {props.route === "cnccutting" ?
                    <React.Fragment>
                        <h3 className="header">Some examples of our work</h3>

                        <Examples route={sub} examples={examples.examples} />
                    </React.Fragment> : null
                }

                {modal ?
                    <Modal close={() => setModal(false)}>
                        <QuotePopup subject={sub} />
                    </Modal>
                    : null}
            </div>
        )
    }
    else {
        return (
            <div className={styles.services}>
                <h3 className="header">Services:</h3>
                <SecondNav route={props.route} subs={props.examples} notNav={true} />
            </div>
        )
    }
}

export default Services;