import React from 'react';
import styles from './NavLogo.module.scss';
import { NavLink } from 'react-router-dom';
import navstyles from '../NavItem/NavItem.module.scss';
import logo from '../../../assets/images/logo.svg';

const NavLogo = (props) => {
    let classes = styles.navlogo;

    if (props.mobile) {
        classes = [styles.navlogo, styles['navlogo--mobile']].join(' ');
    }

    return (
        <NavLink onClick={props.onClick} to={"/"} className={[classes, navstyles.navitem, navstyles.active].join(' ')} style={{ animationDelay: props.delay + 's' }}>
            <img style={{height: "6.5rem"}} src={logo} alt="logo" />
        </NavLink>
    )
}

export default NavLogo;