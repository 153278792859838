import React from 'react';
import styles from './NavigationDropdown.module.scss';

const NavigationDropdown = (props) => {
    let classes = styles.navigationdropdown;

    if (props.toggle) {
        classes = [styles.navigationdropdown, styles["navigationdropdown--active"]].join(' ');
    }

    return (
        <div className={classes}>

        </div>
    )
}

export default NavigationDropdown;