import React from 'react';
import styles from './Terms.module.scss';
import pdf from '../../assets/OECW-Terms-and-Conditions.pdf';

const Terms = (props) => {
    return (
        <div className={styles.terms}>
            <h3 className="header">Terms & Conditions</h3>

            <p className="text">Labour, time, materials, parts &amp; spares quoted are an Estimate Only and 
                may be changed accordingly to the actual labour, time, materials, parts &amp; spares used, 
                replaced and or supplied as required.</p>
            <p className="text">Three months warranty provided on all work done only, excluding,
                consumables, fair wear &amp; tear, negligence, lightning damage, water / liquid damage, 
                power surges and or power spikes.</p>
            <p className="text">Goods not collected within 60 days of notification will be disposed of and/or 
                sold to defray expenses &amp; storage fees, if applicable.</p>
            <p className="text">Terms and conditions apply and E &amp; OE.</p>
            <p className="text">All prices exclude 15% GST and are subject to change and exchange rate fluctuations 
                apply at the time of order & delivery.</p>
            <p className="text">Assessment fees are applicable for jobs and jobs not accepted, assessed and uneconomical to repair.</p>
            <p className="text">Please view the full terms and conditions below.</p>

            <embed src={pdf} type="application/pdf" width="100%" style={{height: "70rem"}} />
        </div>
    )
}

export default Terms;