import React, { useEffect, useState } from 'react';
import styles from './Gears.module.scss';
import Gear1 from '../../assets/images/gear1.svg';
import Gear2 from '../../assets/images/gear2.svg';

const Gears = (props) => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (props.left) {
                setScroll(-window.pageYOffset);
            }
            else {
                setScroll(window.pageYOffset);
            }
        }, { passive: true })
    }, [])

    return (
        <React.Fragment>
            <img style={{ transform: "rotate(" + scroll / 4 + "deg" + ")" }}
                className={[styles.gears, props.left ? styles['gears--left'] : styles['gears--right']].join(' ')}
                src={Gear1} alt="gear 1" />
            <img style={{ transform: "translateX(2rem) translateY(8rem) rotate(" + -scroll / 3.222 + "deg" + ")", width: "8rem"}}
                className={[styles.gears, props.left ? styles['gears--left'] : styles['gears--right']].join(' ')}
                src={Gear2} alt="gear 2" />
        </React.Fragment>
    )
}

export default Gears;