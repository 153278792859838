import React from 'react';
import styles from './ExamplePopup.module.scss';

const ExamplePopup = (props) => {
    return (
        <div className={styles.examplepopup}>
            test
        </div>
    )
}

export default ExamplePopup;