import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavItem.module.scss';
import SecondNav from '../SecondNav/SecondNav';

const NavItem = (props) => {
    const [display, setDisplay] = useState(false);

    const click = () => {
        props.onClick();
        setDisplay(false);
    };

    let classes = styles.navitem;

    if (props.hide) {
        classes = [styles.navitem, styles.hide].join(' ');
    }

    if (props.mobile) {
        classes = [styles.navitem, styles['navitem--mobile']].join(' ');
    }

    const isHomeActive = (match, location) => {
        if (match) {
            if (match.url === "") {
                if (match.url === location.pathname) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    return (
        <div style={{width: "100%"}} onMouseEnter={() => setDisplay(true)} onMouseLeave={() => setDisplay(false)}>
            <NavLink onClick={click}
                className={classes}
                to={props.link}
                isActive = {(match, location) => isHomeActive(match, location)}
                activeClassName={styles.active}
                style={{ animationDelay: props.delay + 's' }} >
                <span>{props.text}</span>
            </NavLink>

            {props.subs != null ? <div style={display ? {display: 'flex'} : {display: 'none'}}>
                <SecondNav click={() => setDisplay(false)} route={props.name.replaceAll(" ", "").replaceAll(",", "")} subs={props.subs} />
            </div> : null}
        </div>
    )
}

export default NavItem;
