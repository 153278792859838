import React from 'react';
import styles from './PopularServices.module.scss';
import { Carousel } from '3d-react-carousal';
import Service from './Service/Service';

const PopularServices = (props) => {
    let slides = Object.keys(props.examples).map((example) => {
        return <Service key={example} example={example} category={props.examples[example]} />
    })

    return (
        <div className={styles.popularservices}>
            <p className="header">Popular services</p>
            <Carousel slides={slides} autoplay={true} interval={10000} />
        </div>
    )
}

export default PopularServices;