import React from 'react';
import styles from './Close.module.scss';

const Close = (props) => {
    return (
        <div className={styles.close} onClick={props.clicked}>
            
        </div>
    )
}

export default Close;