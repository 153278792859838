import React from 'react';
import styles from './AboutUs.module.scss';

const AboutUs = (props) => {
    return (
        <div className={styles.aboutus}>
            <h3 className="header">about us</h3>
            <p className="text">
                Our passion for electronics and CNC cutting
                started as a hobby in a small garage about thirty years ago.
                Our new warehouse and workshop is located in Greymouth, a small
                town on the south island in New Zealand.
            </p>
            <p className="text">
                We love helping people bring their design projects to life and
                provide in-house design services using various CAD and CAM software.
            </p>
            <p className="text">
                Our plasma CNC machine can cut projects with a maximum size of
                3000 mm x 2000 mm and thickness of 25mm, depending on material type.
                Refer to our CNC Cutting Plasma section for more details.
            </p>
            <p className="text">
                Our Router flatbed table size is 6000 mm x 2400mm, with a cutting depth of 100mm.
                Refer to our CNC Cutting, Router section for more details.
            </p>
            <p className="text">
                We offer many electronic repair services, ranging from mobile phones, 
                Apple devices, Computers, Laptops and CCTV systems to telecom equipment, camera equipment, 
                farming equipment and electronics high-level board repair. If it is broken, we can fix it.
            </p>
            <p className="text">
                We offer a 90-day warranty on the work we have done. See the terms and 
                conditions section for more information.
            </p>
        </div>
    )
}

export default AboutUs;