import React from 'react';
import styles from './MessageSent.module.scss';
import runningkiwi from '../../../assets/images/runningkiwi.png';

const MessageSent = (props) => {
    if (props.message) {
        return (
            <div className={styles.messagesent}>
                <h3>Message sent!</h3>
                <p className="text">Thanks for sending us a message.</p>
                <p className="text">We will get back to you soon.</p>
                <img src={runningkiwi} style={{height: "15rem", marginTop: "5rem"}} />
            </div>
        )
    }
    else {
        return (
            <div className={styles.messagesent}>
                <h3>Message failed...</h3>
                <p className="text">Your message has failed unexpectedly...</p>
                <p className="text">Please try again, and if the issue persists, reach out to us through our social media, or at info@ottocnc.co.nz!</p>
            </div>
        )
    }
}

export default MessageSent;