import React, { useState } from 'react';
import styles from './Example.module.scss';
import ExamplePopup from './ExamplePopup/ExamplePopup';
import Modal from '../../../UI/Modal/Modal';
import { images } from '../../../../config';

const Example = (props) => {
    const [modal, setModal] = useState(false);

    return (
        <React.Fragment>
            <div className={styles.example} onClick={() => setModal(false)}>
                <p className={styles["example__header"]}>{props.example}</p>

                <div>
                    <img src={images(props.route, props.example.toLowerCase().replaceAll(" ", "").replaceAll("-", ""))}
                        alt="example item"
                        className={styles["example__image"]}
                    />
                </div>
            </div>
            {modal ?
                <Modal close={() => { setModal(false) }}>
                    <ExamplePopup close={() => { setModal(false) }} />
                </Modal>
                : null}
        </React.Fragment>
    )
}

export default Example;