import 'react-app-polyfill/ie11';
import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.scss';
import './assets/fonts.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

reportWebVitals();
