import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import logo from '../../assets/images/logo.svg';
import nz from '../../assets/images/nz.png';

const Footer = (props) => {
    return (
        <div className={styles.footer}>
            <img className={styles["footer__logo"]} src={logo} alt="logo" />

            <div>
            <Link to="/terms&conditions" className={styles["footer__link"]}>Terms & conditions</Link>
            <Link to="/contactus" className={styles["footer__link"]}>Contact us</Link>
            </div>

            <div className={styles["footer__social"]}>
                <a style={{ textDecoration: "none" }} href="https://www.facebook.com/OTTO-Electronic-and-CNC-Works-106616784991761" target="_blank" rel="noreferrer">
                    <i className="icon-facebooksmall" />
                </a>
                <a style={{ textDecoration: "none" }} href="https://www.instagram.com/otto.cnc/" target="_blank" rel="noreferrer">
                    <i className="icon-instagramsmall" />
                </a>
                <a style={{ textDecoration: "none" }} href="https://www.twitter.com" target="_blank" rel="noreferrer">
                    <i className="icon-twittersmall" style={{ fontSize: "3.5rem" }} />
                </a>
                <a style={{ textDecoration: "none" }} href="https://www.pinterest.nz/oecwltd/_created/" target="_blank" rel="noreferrer">
                    <i className="icon-pinterestsmall" />
                </a>
            </div>

            <p className={styles["footer__reference"]}>Website designed & built by
                <a href="https://www.designsbynicoletta.co.nz" target="_blank" rel="noreferrer"> Designs by Nicoletta</a>
            </p>

            <img className={styles["footer__nz"]} src={nz} alt="Made in New Zealand" />
        </div>
    )
}

export default Footer;