import React from 'react';
import NavigationDropdown from './NavigationDropdown/NavigationDropdown';
import styles from './NavigationDropdownButton.module.scss';

const NavigationDropdownButton = (props) => {
    let classes = styles["navigationdropdownbutton__button"];

    if (props.navToggle) {
        classes = [styles["navigationdropdownbutton__button"], styles["navigationdropdownbutton__button--active"]].join(' ');
    }

    return (
        <div className={styles.navigationdropdownbutton} onClick={() => props.setNavToggle(!props.navToggle)}>
            <NavigationDropdown toggle={props.navToggle} />
            <span className={classes} />
        </div>
    )
}

export default NavigationDropdownButton;