import React, { useState, useEffect } from 'react';
import styles from './QuotePopup.module.scss';
import axios from '../../axios';
import Spinner from '../../components/UI/Spinner/Spinner';
import MessageSent from '../../components/QuoteComponents/MessageSent/MessageSent';
import Button from '../../components/UI/Button/Button';
import Close from '../../components/QuoteComponents/Close/Close';

const QuotePopup = (props) => {
    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState(null);
    const [form, setform] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
        file: "none"
    });

    useEffect(() => {
        if (props.subject) {
            let newForm = { ...form };

            newForm.subject = props.subject;

            setform(newForm);
        }
    }, []);

    const submit = (e) => {
        e.preventDefault();

        setWaiting(true);

        let formData = new FormData();
        formData.append("name", form.name);
        formData.append("email", form.email);
        formData.append("subject", form.subject);
        formData.append("message", form.message);
        formData.append("file", form.file);

        axios.post('/sendMessage', formData, {'Content-Type': "multipart/form-data"})
            .then((res) => {
                if (res.status === 200) {
                    setWaiting(false);
                    setMessage(true);
                }
            })
            .catch(err => {
                setWaiting(false);
                setMessage(false);
            });
    };

    const changeHandler = (e, type) => {
        let newForm = { ...form };

        if (type === "file") {
            if (e.target.files[0].size > 20971520) {
                alert("File is too big!");
            }
            else {
                newForm[type] = e.target.files[0];
            }
        }
        else {
            newForm[type] = e.target.value;
        }

        setform(newForm);
    };

    let completed = true;

    for (let input of Object.values(form)) {
        if (input === "") {
            completed = false;
            break;
        }
    }

    return (
        <div className={styles.quotepopup}>
            <form className={styles["quotepopup__form"]} onSubmit={submit}>
                {waiting ? <Spinner /> :
                    <React.Fragment>
                        {message != null ? <MessageSent message={message} /> :
                            <React.Fragment>
                                <h3>Send us a message</h3>
                                <table className={styles["quotepopup__table"]}>
                                    <tbody>
                                        <tr className={styles["quotepopup__row"]}>
                                            <td><label>Name: </label></td>
                                            <td><input placeholder="*" onChange={(e) => changeHandler(e, "name")} className={styles["quotepopup__input"]} maxLength={20} type="text" name="name" value={form.name} required /></td>
                                        </tr>
                                        <tr className={styles["quotepopup__row"]}>
                                            <td><label>Email: </label></td>
                                            <td><input placeholder="*" onChange={(e) => changeHandler(e, "email")} className={styles["quotepopup__input"]} maxLength={30} type="email" name="email" value={form.email} required /></td>
                                        </tr>
                                        <tr className={styles["quotepopup__row"]}>
                                            <td><label>Subject: </label></td>
                                            <td><select onChange={(e) => changeHandler(e, "subject")} className={styles["quotepopup__input"]} name="subject" value={form.subject} required>
                                                <option value="" disabled>Please select an option:</option>
                                                <option value="message">General enquiries</option>
                                                <option value="router">Router</option>
                                                <option value="plasma">Plasma</option>
                                                <option value="mobiledevices">Mobile devices</option>
                                                <option value="computerslaptops">Computers, laptops</option>
                                                <option value="appledevices">Apple devices</option>
                                                <option value="cctvsystems">CCTV systems</option>
                                                <option value="pcboards&other">PC boards & other</option>
                                                <option value="telecomequipment">Telecom equipment</option>
                                            </select></td>
                                        </tr>
                                        <tr className={styles["quotepopup__row"]}>
                                            <td style={{ verticalAlign: "top" }}><label>Message: </label></td>
                                            <td style={{ position: "relative" }}>
                                                <textarea placeholder="*" onChange={(e) => changeHandler(e, "message")} className={[styles["quotepopup__input"], styles["quotepopup__input--message"]].join(" ")}
                                                    type="text"
                                                    name="message"
                                                    maxLength={2000}
                                                    value={form.message}
                                                    required
                                                />
                                                <p className={styles["quotepopup__wordcount"]}>
                                                    {form.message.length + "/2000"}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr className={styles["quotepopup__row"]}>
                                            <td style={{ verticalAlign: "top" }}><label>File: </label></td>
                                            <td style={{ position: "relative" }}>
                                                <input
                                                    type="file"
                                                    onChange={(e) => changeHandler(e, "file")}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {completed ?
                                    null :
                                    <p className={styles["quotepopup__required"]}>* Please complete required fields</p>
                                }
                                <Button colour="purple">Send</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </form>

            <Close clicked={props.close}/>
        </div>
    )
}

export default QuotePopup;